import React from "react"
import ArticleCard from "../components/ArticleCard"
import { FaSearch } from "react-icons/fa"
import JobContent from "../components/JobContent"
import { useQuery, NetworkStatus } from "@apollo/client"
import { SEARCH_ARTICLE, SEARCH_JOB } from "../crud/getData"
import Loader from "../components/Loader"
import SearchError from "./SearchError"
const SearchResults = ({ idSearch, idType }) => {
  const { data: dataArticleFind, loading, error, networkStatus } = useQuery(
    SEARCH_ARTICLE,
    {
      variables: {
        key: idSearch,
      },
    }
  )
  const { loading: jobLoading, data: dataFindJobs } = useQuery(SEARCH_JOB, {
    variables: { key: idSearch, language: "Japanese" },
  })
  if (networkStatus === NetworkStatus.refetch) return <Loader lang={"ja"} />
  if (loading || jobLoading) return <Loader lang={"ja"} />
  if (error) return <SearchError />
  
  return (
    <>
      {idType === "jobs" ? (
        <div className="jobs-listing__job-info jobs-listing__search-page">
          {dataFindJobs.jobs.length === undefined ? (
            ""
          ) : (
            <h6>
              検索条件「{idSearch}」に合う求人は {dataFindJobs.jobs.length} 件ありました。              
            </h6>
          )}

          {dataFindJobs.jobs.length === 0 ? (
            <div className="no-results">
              <FaSearch />
              <p>結果がありません</p>
            </div>
          ) : (
            <>
              {dataFindJobs.jobs.map((jobs, i) => (
                <React.Fragment key={"jobContent_" + i}>
                  <JobContent jobs={jobs} lang={"ja"} />
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="jobs-listing__articles">
          {dataArticleFind.titles.length === 0 ? (
            ""
          ) : (
            <>
              <h6>
                検索条件に合う記事は {dataArticleFind.titles.length}
                件ありました。
              </h6>
              <span>{idSearch}</span>
              <hr className="u-line-bottom" />
            </>
          )}

          {dataArticleFind.titles.length === 0 ? (
            <div className="no-results">
              <FaSearch />
              <p>結果がありません</p>
            </div>
          ) : (
            <>
              {dataArticleFind.titles.map((news, i) => (
                <ArticleCard article={news} />
              ))}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SearchResults
