import React, { useContext, useEffect } from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { SearchContext } from "../context/SearchContext"
import { Seo } from "../components/seo"
import SearchResults from "../components/SearchResults"
import { FaSearch } from "react-icons/fa"
const Search = props => {
  const idSearch = props.search.keyword
  const idType = props.search.type
  const { setText, setType } = useContext(SearchContext)
  useEffect(() => {
    setText({ text: idSearch })
    setType({ type: idType })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <Seo
        title={`検索結果: ${idSearch === undefined ? "No Key" : idSearch}`}
      />
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
            </div>
            {idSearch && idType ? (
              <SearchResults idSearch={idSearch} idType={idType} />
            ) : (
              <div className="no-results">
                <FaSearch />
                <p>結果がありません</p>
              </div>
            )}

            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default Search

export const Head = () => {
  return <Seo title="メキシコ求人・就職・転職情報``" />
}
